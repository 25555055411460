import React from 'react';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <h1 className="text-3xl text-fuchsia-50 hover:text-fuchsia-700 hover:cursor-pointer font-bold underline">
          Coming soon...
        </h1>
      </header>
    </div>
  );
}

export default App;
